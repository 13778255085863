<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" :style="cursorStyle" @click="dialogOpen()">

            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="12" class="pb-0" :md="6">
                        <span class="font-weight-bold"> {{ item.date }} •
                            <span class="gold--text">VS {{ g_isDefined(item.symbol) }}</span>
                        </span>
                    </v-col>
                    <v-col cols="12" :md="6" class="font-weight-medium">
                        <span class="gold white--text px-3 rounded">{{ item.amount }}</span>
                        <span class="tag-status white--text rounded px-3 ml-1" :class="state"> {{ item.status }}</span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-col cols="12">
                        <v-row align="center" no-gutters>
                            <span class="headline text-uppercase gold--text" style="font-size: 20px !important;">
                                {{ g_parseBankAccount(item.account) }}
                            </span>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0"
                                   style="'width: calc(100% /' + keys.length + ')'">
                                <v-row no-gutters v-if="checkRouteName">
                                    <span class="caption gold--text">{{
                                            $t($route.name + '.table_keys.' + v.value)
                                        }}</span>
                                </v-row>
                                <v-row no-gutters v-else>
                                    <span class="caption gold--text">{{
                                            $t('transactions.table_keys.' + v.value)
                                        }}</span>
                                </v-row>

                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ item[v.value] }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!--FOOTER-->
                    <v-col cols="12" class="pt-2">
                        <v-row no-gutters align="center" class="pa-0 ma-0 rounded">
                            <v-icon size="16" class="pr-3">mdi-comment-text</v-icon>
                            <span class="caption">{{ g_isDefined(item.details) }}</span>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-dialog
                v-model="edit_dialog"
                persistent
                max-width="540"
            >
                <v-alert v-if="edit_dialog" class="my-0 font-weight-medium" border="left">
                    <v-alert type="info" color="gold" text dense class="caption">
                        {{ item.date }} • <strong>{{ item.account }}</strong> • {{ item.amount }} <br>
                    </v-alert>
                    <v-row align="center">
                        <v-col class="grow">
                            <span class="body-2">{{ $t('transactions.edit_symbol') }}</span>
                            <v-form @submit.prevent="updateSymbol" id="form" ref="form">
                                <v-text-field class="gold-price"
                                              v-model="edited_symbol" color="gold"
                                              outlined dense
                                              persistent-hint
                                />
                            </v-form>
                        </v-col>
                        <v-col class="shrink">
                            <v-btn small dense depressed class="gold white--text mb-4" width="128"
                                   @click="updateSymbol">
                                {{ $t('common.ok') }}
                            </v-btn>
                            <v-btn small dense text outlined width="128"
                                   @click="dialogClose">
                                {{ $t('common.cancel') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>

            </v-dialog>
        </v-card>
    </v-hover>
</template>

<script>
import {updateBankTransactionRecord} from "@/api/transactions";
import {Utils} from "@/services/utils";

export default {
    name: "BankTransactionCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined
    },
    data: () => ({
        edit_dialog: false,
        edited_symbol: "",
        card: {cols: 6, xs: 6, sm: 4, md: 1, align: 'center', class: 'my-2 full-cols'}
    }),
    computed: {
        checkRouteName() {
            return this.$route.name === 'transactions';
        },

        cursorStyle() {
            if (this.item.index !== undefined) {
                return "cursor: pointer !important;"
            } else {
                return ""
            }
        }
    },
    methods: {
        updateSymbol() {
            updateBankTransactionRecord(this.item.index, this.edited_symbol)
                .then((response) => {
                    this.edit_dialog = false;
                    this.item.symbol = response.data.symbol
                    this.item.status = Utils.localizeText("transactions.type." + response.data.type)
                    this.item.raw_data = response.data
                    this.$root.$emit('notification', {
                        type: 'success',
                        icon: 'update',
                        msg: 'notification.updated_symbol'
                    });
                }).catch(() => {
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'server_error',
                    msg: 'notification.server_error'
                });
            })
        },

        dialogOpen() {
            if (this.item.index !== undefined) {
                this.edited_symbol = this.item.symbol
                this.edit_dialog = true
            }
        },

        dialogClose() {
            this.edit_dialog = false;
        },
    }
}
</script>